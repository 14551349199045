//
// Breadcrumbs
// --------------------------------------------------

.top-header{
	background-color: @breadcrumb-bg;
	overflow:hidden;
}
.breadcrumb {
	padding: 5px @layout-padding-mobile;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
	text-transform: uppercase;
	font-size: 12px;
	margin-bottom: 0;
	@media (min-width: @grid-float-breakpoint) {
	  float: left;
	  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
	}

  > li {
    display: inline-block;

    + li:before {
      content: "\f105"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
			font-family: fontAwesome;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}

.breadcrumb strong {
  font-weight: normal;
  color: @breadcrumb-active-color;
}

.code-header{
	padding: 5px @layout-padding-mobile;
	background-color: @breadcrumb-bg;
	text-transform: uppercase;
	font-weight: 500;
	text-align: right;
	color: @brand-primary;
	font-size: 12px;
	@media (min-width: @grid-float-breakpoint) {
		float: right;
	  width: 50%;
	  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
	}
	.code{
		color: @text-color;
	}
}