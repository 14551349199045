//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  .container-fixed();

  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }
	@media (min-width: @screen-lg-plus-min) {
    width: @container-lg-plus;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  margin: 0 auto;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  .make-row();
}


// Columns
//
// Common styles for small and large grid columns

.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-grid(lg);
}


//MAGENTO

.col2-set,
.col2-set,
.col4-set {
  .make-row();
}

// Content Columns
.col2-set {

  > .legend {
    margin-left: (@grid-gutter-width / 2);
    margin-right: (@grid-gutter-width / 2);
    width: auto;
  }

  .col-1,
  .col-2 {
    .make-sm-column(6);
  }
  .col-narrow {
    .make-sm-column(4);
  }
  .col-wide {
    .make-sm-column(8);
  }
}

.col3-set {

  .col-1,
  .col-2,
  .col-3 {
    .make-sm-column(4);
  }
}

.col4-set {

  .col-1,
  .col-2,
  .col-3,
  .col-4 {
    .make-sm-column(3);
  }
}
