//
// Close icons
// --------------------------------------------------

.close-cart {
	text-align: right;
	padding: @line-height-computed/2;
	border-bottom: 1px solid @color-grey;
	a{
		display: inline-block;
		text-align: center;
		text-transform: uppercase;
		font-size: 10px;
		color: @color-grey-text;
		i{
			display: block;
			font-size: 21px;
			padding-bottom:5px;
		}
	}
}
.close {
  font-size: @font-size-base;
  font-weight: @close-font-weight;
  line-height: 1;
  color: @close-color;

  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
    .opacity(.5);
		outline: 0;
  }
	

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
